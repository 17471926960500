const faqs = [
  {
    question: "Requisitos para viajar a Francia",
    answer: (
      <div>
        <p>-  Pasaporte y visa: Lo primero que debes tener es un pasaporte vigente. Asegúrate de que su fecha de expiración sea válida durante todo el período de tu estadía en Francia. Es importante recalcar que para viajar a Europa como turistas, los mexicanos no requieren una visa Schengen.</p><br/>
        <p>- Reservación de vuelo y alojamiento: Es recomendable que presentes pruebas de tu vuelo de ida y vuelta a Francia, así como de tu alojamiento durante tu estadía. Puede ser una copia impresa o electrónica de las reservas de hotel o una carta de invitación de un anfitrión en Francia, si te quedarás en casa de un familiar o amigo.</p><br/>
        <p>- Seguro de viaje: Aunque no es un requisito obligatorio, se recomienda encarecidamente obtener un seguro de viaje que cubra gastos médicos y de repatriación en caso de emergencias. La asistencia médica en Francia puede ser costosa para los extranjeros, y contar con un seguro te brindará tranquilidad durante tus vacaciones.</p><br/>
        <p>- Demostrar medios económicos: Al llegar a Francia, es probable que te pidan demostrar que cuentas con los recursos financieros suficientes para cubrir tus gastos durante tu estancia. Esto puede incluir extractos bancarios, tarjetas de crédito o cheques de viajero.</p><br/>
        <p>- Certificado de vacunación y salud: Es posible que se te exija un certificado de vacunación contra ciertas enfermedades, así como un certificado de buena salud. Asegúrate de investigar las últimas regulaciones sanitarias antes de viajar y, en caso de requerirlo, programa una cita con tu médico para obtener los documentos necesarios.</p><br/>
        <p>- Declaración de aduana: Al ingresar a Francia, deberás completar una declaración de aduana en la que declares los bienes que llevas contigo, incluidos objetos de valor y cantidades significativas de dinero en efectivo.</p><br/>
        <p>- Cumplir con los requisitos de salud animal: Si viajas con mascotas, asegúrate de cumplir con las regulaciones de salud animal de Francia. Generalmente, se requiere un certificado veterinario y la identificación por microchip.</p><br/>
        <p>- Documentos adicionales: Si tienes algún otro tipo de documento que pueda ser relevante para tu viaje, como una carta de invitación de un evento o un certificado de estudios, llévalo contigo en caso de que las autoridades lo requieran.</p><br/>
    
      </div>
    ),
  },

 
];

export default faqs;
